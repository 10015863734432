import React, { Component } from "react"
import { Link } from "gatsby"
import "../assets/fonts/font.css"
import "../assets/css/faq.css"
// import Logo from "../images/logo.png"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';


// import appStoreIcon from "../images/app-store-icon.png"
// import googlePlayIcon from "../images/google-pay-icon.png"
// import fbIcon from "../images/fb-icon.svg"
// import twitterIcon from "../images/twitter.svg"
// import instacon from "../images/insta-icon.svg"

// import video from "../images/video-icon.svg"
import lineImage from "../images/line2.png"

// import mailIcon from "../images/mail-icon.svg"
// import callIcon from "../images/call-icon.svg"
// import livechat from "../images/live-chat-icon.svg"

import Layout from "../components/layout"

// import newslatter from "../images/newslatter.svg"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import SEO from "../components/seo"

// import benefitsIcon from '../images/faq/benefits.svg'
// import claimsIcon from '../images/faq/claims.svg'
// import coveredIcon from '../images/faq/covered.svg'
// import messageIcon from '../images/faq/message.svg'
// import policyIcon from '../images/faq/policy.svg'
// import umbrellaIcon from '../images/faq/umbrella.svg'

import canceltransfer from '../images/faq/cancel-transfer.svg'
import claiminfo from '../images/faq/claim-info.svg'
import coverageinfo from '../images/faq/coverage-info.svg'
import generalinfo from '../images/faq/general-info.svg'
import needhelp from '../images/faq/need-help.svg'
import stacksinfo from '../images/faq/stacks-info.svg'

class FaqPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
      fixedHeaderHeight: 0,
      activeTab: 'tab1',
      tabs: {
        tab1: React.createRef(),
        tab2: React.createRef(),
        tab3: React.createRef(),
        tab4: React.createRef(),
        tab5: React.createRef(),
        tab6: React.createRef()
      }
    }
  }

  render() {
    let cardImageClasses = 'card-image';
    if (this.state.scrolled > 0) {
      cardImageClasses = 'card-image hide';
    } else {
      cardImageClasses = 'card-image';
    }

    return (
      <Layout rest={this.props} layoutType="main">
        <SEO title="Servicecontract.com - FAQ" keywords={[`Servicecontract FAQ`, `Service Warranty`]} />
        <div className="chat-content" style={{ display: 'none' }}>
          <div className="chat-content-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
              <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                  <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
          <div className="chat-box">

            <div className="chatbox-inner">
              <div className="chat-profile">
                <img className="" src={chatProfile} alt="" />
              </div>
              <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
              <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
            </div>
            <img className="chat-bottom" src={chatbottom} alt="" />
          </div>

        </div>

        <section className="faq-page-container">
          <div id="faqPageHeader" className="faq-tab-header">
            <span className={this.state.activeTab === 'tab1' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab1')}>
              <span className={cardImageClasses}>
                <img src={generalinfo} alt="General" />
              </span>
              <h4 className="card-title">General Info</h4>
            </span>
            <span className={this.state.activeTab === 'tab2' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab2')}>
              <span className={cardImageClasses}>
                <img src={coverageinfo} alt="Coverage" />
              </span>
              <h4 className="card-title">Coverage Info</h4>
            </span>
            <span className={this.state.activeTab === 'tab3' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab3')}>
              <span className={cardImageClasses}>
                <img src={claiminfo} alt="Claims" />
              </span>
              <h4 className="card-title">Claims Info</h4>
            </span>
            <span className={this.state.activeTab === 'tab4' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab4')}>
              <span className={cardImageClasses}>
                <img src={stacksinfo} alt="Stacks" />
              </span>
              <h4 className="card-title">Stacks Info</h4>
            </span>
            <span className={this.state.activeTab === 'tab5' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab5')}>
              <span className={cardImageClasses}>
                <img src={canceltransfer} alt="Cancel Info" />
              </span>
              <h4 className="card-title">Cancel Info</h4>
            </span>
            <span className={this.state.activeTab === 'tab6' ? 'card active' : 'card'} onClick={() => this.handleScroll('tab6')}>
              <span className={cardImageClasses}>
                <img src={needhelp} alt="I Need Help!" />
              </span>
              <h4 className="card-title">I Need Help!</h4>
            </span>
          </div>
          <div className="faq-tab-container">
            <div ref={this.state.tabs.tab1} className="tab-container">
              <h2 className="tab-title">General Info</h2>
              <div className="tab-content">

                <h4>What’s different about servicecontract.com’s service contracts?</h4>
                <p>The most common way to purchase a service contract is to buy it at the time you purchase your vehicle. When you purchase it at that time, 
                  you’re required to choose a term and mileage limited contract that gets wrapped 
                  into your financing and may cost thousands of dollars. Servicecontract.com 
                  contracts are all month-to-month, so there’s no long-term obligation.
                  They’re also easily modified to meet your changing needs. Our goal is to make your life easier, and this type of contract gives you the ultimate flexibility.</p>
                <br />

                <h4>How much do I have to pay today?</h4>
                
                <p>You only need to submit your first month’s payment with your contract purchase. Further payment will be handled through easy monthly billing. </p>
                <br />

                <h4>Is a service contract really an extended warranty?</h4>
                <p>Although people commonly refer to service contracts as “extended 
                  warranties,” there is an important distinction between the two.  
                  Warranties are  issued with your vehicle
                  from the manufacturer, and are included in the price, 
                  whether you purchase a new or certified pre-owned vehicle.  
                  A service contract is coverage you voluntarily purchase to protect
                  your vehicle beyond the expiration of  your factory warranty.</p>
               <br />
               
                <h4>Why do I need a service contract if my vehicle has a factory warranty?</h4>
                <p>Most vehicle factory warranties expire 3 years from the 
                  purchase date or when the vehicle’s odometer reaches 36,000 miles.  
                  Coverages can range from comprehensive (“bumper-to-bumper”) to powertrain.  Once your vehicle 
                  eclipses its factory warranty, you may be responsible for the cost of all 
                  repairs when mechanical failures occur.  A service contract provides financial protection and peace of mind by covering the cost 
                   of unexpected repairs.</p>
                <br />

                <h4>Should I just wait to buy a service contract if my vehicle has a factory warranty?</h4>
                <p>The right time to buy a service contract is a personal decision.  
                  The closer your vehicle gets to the expiration of its factory warranty, 
                  the more expensive the service contract is going to become.  
                  If you plan on keeping your vehicle past the limits of its factory 
                  warranty, then there is a significant benefit to locking in 
                  lower rates, and extended protection, by purchasing sooner rather than later. 
                  Similar to life insurance, coverage for a younger, “healthier” 
                  car will be less costly than for that of an older vehicle even though 
                  it may still be in its prime.</p>
                <br />

                <h4>Doesn’t a service contract provide the same coverage as my auto insurance?</h4>
                <p>Not at all!  Your auto insurance is there to cover you should you be involved 
                  in a collision or some other unfortunate  event (theft, weather damage, etc.).  
                  A service contract covers the mechanical portion of your vehicle, 
                  which auto insurance does not. If you’re willing to pay thousands 
                  of dollars to protect the outside of your vehicle, you should consider 
                  the value of paying a fraction of that to cover the vital functions inside
                  as well.</p>
                <br />
                
                <h4>Can I only buy a service contract if my vehicle has remaining factory warranty?</h4>
                <p>No, you can buy a service contract even if your vehicle is out of factory warranty coverage.  We do our best to accommodate as many vehicles as we can!</p>
                <br />

                <h4>What are the age or mileage limits to buy a service contract?</h4>
                <p>We offer service contracts for vehicles with a model year 2000 and newer and have coverages for vehicles with up to 150,000 miles on their odometer.</p>
                <br />

                <h4>What information about my vehicle do I need to purchase a service contract?</h4>
                <p>We will need the vehicle year, make, model, and mileage.  We will also need the vehicle’s VIN and information about any types of extra features 
                  it may have (4x4, AWD, Turbo, Supercharger, etc.) as well submission of a photo of your vehicle’s odometer to confirm current mileage.</p>
                <br />
                
                <h4>What’s a VIN?</h4>
                <p>A VIN is a vehicle identification number.  This number is 17 digits long 
                  and specifically identifies your vehicle.  You can find this number  
                  on the inside of your door or at the bottom corner of the windshield on the driver’s side. 
                  This number will verify the information we need in order 
                  to give you an accurate service contract price quote.</p>
                <br />

                <h4>What is a deductible and how does it work?</h4>
                <p>A deductible is a small amount that you elect to pay towards any claim that
                   you may have.  For example, if your deductible is $100 and your repair is 
                   $2,500, you would pay $100 and we would pay $2,400. Servicecontract.com offers 
                   various deductible options, some as low as $0! 
                   Why would you elect to pay a deductible? Well, the higher your deductible, 
                   the less expensive your contract will be.  Not sure which deductible 
                   to select?  Just ask us, we’re here to help!</p>
                <br />
                
                <h4>What if I can’t afford my contract?</h4>
                <p>There are many options available so it’s easy to find one to meet your budget.
                   Simply call us at 1-800-800-3629 and 
                  we will guide you through our coverage offerings.  We even offer interest-free financing to make 
                  paying for your contract simple.  Remember—our number-one goal is to ensure  
                  our customers are completely satisfied, so always call us if you have any 
                  questions or issues.</p>
                <br />
                
                <h4>Do I need to have my vehicle inspected to buy a service contract?</h4>
                <p>An inspection is not required in order to buy a service contract. 
                  But if you have your vehicle inspected, we will allow full coverage to 
                  begin on day 1 (rather than waiting 30 days for breakdown coverage).</p>
                <br />

                <h4>Where can I have my vehicle inspected?</h4>
                <p>Please call or email us!  1-800-800-3629 or 
                  <a href="mailto:help@servicecontract.com"> help@servicecontract.com </a> 
                  for help finding a convenient inspection center.
                </p>
                

              </div>
            </div>
            <div ref={this.state.tabs.tab2} className="tab-container">
              <h2 className="tab-title">Coverage Info</h2>
              <div className="tab-content">

                <h4>Does my service contract cover everything?</h4>
                <p>No, and don’t believe anyone that tells you theirs does!  There are always 
                  certain exclusions of coverage, namely accident repairs and basic 
                  maintenance (oil changes, tire rotations, etc.).  Depending on the 
                  level of coverage you choose, we will either specifically list what is 
                  covered and/or tell you what is not covered.  There are no secrets with our contracts.</p>
                
                  <br />
                <h4>What is ID Theft Protection and how much does it cost?</h4>
                <p>ID Theft Protection is a highly valuable protective product that uses innovative technology to offer multiple layers of protection against identity theft and identity fraud threats, including best-in-class fraud prevention and remediation services. With ID Theft Protection, you and your family are covered by a comprehensive suite of services and benefits, including dark web and financial monitoring, identity threat alerts, single bureau credit monitoring, stolen funds reimbursement, and up to $1 million in identity theft insurance. ID Theft Protection features 24/7 customer service and provides peace of mind through robust protection and real, tangible remedy should identity theft or fraud occur. This product is provided free of charge with your Vehicle Service Contract or LeaseCare VSC purchase.</p>
              </div>
            </div>
            <div ref={this.state.tabs.tab3} className="tab-container">
              <h2 className="tab-title">Claims Info</h2>
              <div className="tab-content">

                <h4>What if I like to work on my own car?</h4>
                <p>You can use your service contract at any Repair Shop as defined in the 
                  contract.  Even though you may be a weekend mechanic for some basic 
                  repairs, be sure to utilize the protection our service contract provides for 
                  more advanced repairs.</p>
                <br />

                <h4>What do I do if my vehicle breaks down?</h4>
                <p>First and foremost, if your vehicle breaks down while driving, do everything you can to ensure you are safe.  From there, simply call the roadside assistance number located in the contract.  Once your vehicle is towed to the Repair Facility, please let the shop know that you have a service contract and to call the claims number located in the contract prior to beginning any work.  We will take it from there. All you need to do is pick your car up when it’s fixed, and we will pay the shop directly!</p>
                <p>If your vehicle has a failure that does not affect drivability, simply drive it to your local repair shop and have them call the number on your contract. Have them contact us and, again, we’ll take it from there!</p>
                <br />

                <h4>How will I know if my vehicle will be covered if it breaks down?</h4>
                <p>You can always refer to your service contract to determine if a certain part or component is covered.  If you would like us to explain coverage, just call us.  Our experts will assist you to make sure you understand everything that is happening during the process.</p>
                <br />

                <h4>Why would my claim ever get denied?</h4>
                <p>A claim will only be denied if the component is not covered under the 
                  coverage level selected, if the repair was due to a pre-existing issue 
                  with your vehicle, or is otherwise excluded.  A new service contract does not cover 
                  anything that is currently wrong with your vehicle.  If you have any questions
                  about this, please do not hesitate to call us.</p>
                <br />

                <h4 id="stack">What is a deductible and how does it work?</h4>
                <p>A deductible is a small amount that you elect to pay towards any claim that 
                  you may have. For example, if your deductible is $100 and your repair is 
                  $2,500, you would pay $100 and we would pay $2,400. Servicecontract.com offers various deductible options, some as low as $0! Why would you
                   elect to pay a deductible? Well, the higher your deductible, the less expensive your contract will be. Not sure which deductible to select? Just ask us, we’re here to help!</p>
                
              </div>
            </div>
            <div ref={this.state.tabs.tab4} className="tab-container" id="stack">
              <h2 className="tab-title">Stacks Info</h2>
              <div className="tab-content">

              <h4>What is the Stacks Reward Plan?  </h4>
                <p>Stacks is a unique reward plan that is included with your monthly contract purchase, 
                  making your VSC or Lease Care Ultimate Service Contract virtually risk-free. </p>
                <p>If you have no need to make a claim during the term of your service contract, 
                  you will be eligible for reimbursement of your total monthly service contract payments, 
                  minus certain fees.</p>
                <p>Simply put, you pay a monthly fee for valuable coverage, 
                  then get the unused portion of your service contract payments back at the end of the participation term, 
                  less certain fees. <Link to="/legalstuff/#stacks">Terms and conditions </Link> apply. 
                </p>
                
                
              </div>
            </div>
            <div ref={this.state.tabs.tab5} className="tab-container">
              <h2 className="tab-title">Cancel {/* and Transfer */} Info</h2>
              <div className="tab-content">

                <h4>Can I cancel my service contract?</h4>
                <p>You can cancel your service contract at any time! Depending on the 
                length of time you’ve had the contract or the mileage you’ve driven, you may qualify 
                  for a refund of unused premium.</p>
                <br />

                {/* <h4>Can I transfer my service contract?</h4>
                <p>Most of our plans are transferrable to a new vehicle owner.  Simply reach out to our customer service representatives and they will guide you in the process.  This is beneficial because you can help the value of your vehicle if you decide to sell it with your service contract attached to it.</p> */}
                
               
              </div>
            </div>
            <div ref={this.state.tabs.tab6} className="tab-container">
              <h2 className="tab-title">I Need Help!</h2>
              <div className="tab-content">
                <h4>What if I just have questions or need help?</h4>
                <p>This is easy, just call us!  Our number is 1-800-800-3629, or you can email us at <a href="mailto:help@servicecontract.com">  help@servicecontract.com</a> with any questions.</p>
                <br />
              </div>
            </div>
          </div>
        </section>
        {/* <NewsLatter /> */}
        <div className="break-section">
          <img src={lineImage} alt="line" />
        </div>
        <Footer />
      </Layout>
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
    this.setState({ fixedHeaderHeight: document.getElementById('pageHeader').clientHeight + document.getElementById('faqPageHeader').clientHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    this.setState({ fixedHeaderHeight: document.getElementById('pageHeader').clientHeight + document.getElementById('faqPageHeader').clientHeight });
    const winHeight = (window.screen.height/2.5) - this.state.fixedHeaderHeight;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    this.setState({ scrolled: winScroll });

    if (winScroll > this.state.tabs.tab6.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab6' });
    } else if (winScroll > this.state.tabs.tab5.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab5' });
    } else if (winScroll > this.state.tabs.tab4.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab4' });
    } else if (winScroll > this.state.tabs.tab3.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab3' });
    } else if (winScroll > this.state.tabs.tab2.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab2' });
    } else if (winScroll > this.state.tabs.tab1.current.offsetTop - winHeight) {
      this.setState({ activeTab: 'tab1' });
    }
  }

  handleScroll = (currentTab) => {
    var toy = this.state.tabs[currentTab].current.offsetTop - document.getElementById('faqPageHeader').clientHeight;
    
    if (/Edge/.test(navigator.userAgent)) {
      window.scrollTo(0, toy);  
    } else {
      window.scrollTo({ top: this.state.tabs[currentTab].current.offsetTop - document.getElementById('faqPageHeader').clientHeight, behavior: 'smooth' });
    }
    
  }
}


export default FaqPage
